<template>
  <div class="hero">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Hero'
}
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles";

.hero {
  padding-bottom: 75px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 20px;
  overflow: hidden;

  @include below(map_get($grid-breakpoints, sm)) {
    border-top-right-radius: 0;
  }
}
</style>
